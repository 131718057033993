
export default {
  state: {
    stateProfileData: {},
    stateCompanyPhoneType: [],
    stateCompanyDocumentType: [],
    stateCompanies: [],
    stateOperators: [],
    stateDisputeTypes: [],
    stateBureauAuthStatuses: {},
    stateBureauDataStatuses: {},
    stateValue: {},
  },
  mutations: {
    setStateValue(state, {field, value}) {
      state.stateValue[field] = value;
    },
    clearStateValue(state) {
      state.stateValue = {};
    },
    setStateBureauStatus(state, {stateBureauAuthStatuses, stateBureauDataStatuses}) {
      state.stateBureauAuthStatuses = stateBureauAuthStatuses;
      state.stateBureauDataStatuses = stateBureauDataStatuses;
    },
    setStateDisputeTypes(state, disputeTypes) {
      state.stateDisputeTypes = disputeTypes;
    },
    setStateOperators(state, operators) {
      state.stateOperators = operators;
    },
    clearStateOperators(state) {
      state.stateOperators = [];
    },
    setStateCompanies(state, companies) {
      state.stateCompanies = companies;
    },
    clearStateCompanies(state) {
      state.stateCompanies = [];
    },
    setStateProfileData(state, stateProfileData) {
      state.stateProfileData = stateProfileData;
    },
    clearStateProfileData(state) {
      state.stateProfileData = {};
    },
    setStateCompanyPhoneType(state, stateData) {
      state.stateCompanyPhoneType = stateData;
    },
    clearStateCompanyPhoneType(state) {
      state.stateCompanyPhoneType = [];
    },
    setStateCompanyDocumentType(state, stateData) {
      state.stateCompanyDocumentType = stateData;
    },
    clearStateCompanyDocumentType(state) {
      state.stateCompanyDocumentType = [];
    },
  },
  getters: {
    stateValue: s=>s.stateValue,
    stateOperators: s=>s.stateOperators,
    stateCompanies: s=>s.stateCompanies,
    stateDisputeTypes: s=>s.stateDisputeTypes,
    stateProfileData: s=>s.stateProfileData,
    stateCompanyPhoneType: s=>s.stateCompanyPhoneType,
    stateCompanyDocumentType: s=>s.stateCompanyDocumentType,
    stateBureauAuthStatuses: s=>s.stateBureauAuthStatuses,
    stateBureauDataStatuses: s=>s.stateBureauDataStatuses,
  },
  actions: {
    async loadStateProfileData({commit, dispatch, getters}, userId) {
      if (getters.stateProfileData?.id === userId) {
        return getters.stateProfileData;
      }
      const profile = await dispatch('apiGetProfile', userId);
      if (!profile?.email_address) {
        await commit('clearStateProfileData');
        return profile;
      }
      profile.id = userId;
      await commit('setStateProfileData', profile);
      return profile;
    },
    async loadStateCompanyPhoneType({commit, dispatch, getters}) {
      if (getters.stateCompanyPhoneType.length) {
        return getters.stateCompanyPhoneType;
      }
      const data = await dispatch('apiGetBusinessPhoneTypes');
      await commit('setStateCompanyPhoneType', data);
      return data;
    },
    async loadStateCompanyDocumentType({commit, dispatch, getters}) {
      if (getters.stateCompanyDocumentType.length) {
        return getters.stateCompanyDocumentType;
      }
      const data = await dispatch('apiGetBusinessDocumentTypes');
      await commit('setStateCompanyDocumentType', data);
      return data;
    },
    async loadStateCompanies({commit, dispatch, getters}, {userId, isUpdate}) {
      if (getters.stateCompanies.length && !isUpdate) {
        return getters.stateCompanies;
      }
      const data = await dispatch('apiGetBusinesses', {user_id: userId});
      await commit('setStateCompanies', data);
      return data;
    },
    async loadStateOperators({commit, dispatch, getters}) {
      if (getters.stateOperators.length) {
        return getters.stateOperators;
      }
      const data = await dispatch('apiGetOperators');
      await commit('setStateOperators', data);
      return data;
    },
    async loadStateDisputeTypes({commit, dispatch, getters}) {
      if (getters.stateDisputeTypes.length) {
        return getters.stateDisputeTypes;
      }
      const data = await dispatch('apiGetDisputeTypes')?.data||[];
      await commit('setStateDisputeTypes', data);
      return data;
    },
  },
}
