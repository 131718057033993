import apiAxios from "@/api-axios";

export default {
  store: {
    transports: [],
    transportTypes: [],
  },
  mutations: {
    setTransports(state, data) {
      state.transports = data;
    },
    setTransportTypes(state, data) {
      state.transportTypes = data;
    },
  },
  getters: {
    transports: s => s.transports,
    transportTypes: s => s.transportTypes
  },
  actions: {
    async apiGetTransports({dispatch, commit}) {
      try {
        const response = await apiAxios.get(`/transports/`);
        commit('setTransports', response.data);
        return response.data;
      } catch (e) {
        dispatch('catchError', e);
      }
      return [];
    },
    async apiGetTransportTypes({dispatch, commit}) {
      try {
        const response = await apiAxios.get(`/transports/types`);
        commit('setTransportTypes', response.data);
        return response.data;
      } catch (e) {
        dispatch('catchError', e);
      }
      return [];
    },
    async apiCreateTransports({dispatch}, data) {
      try {
        return await apiAxios.post(`/transports/`, data);
      } catch (e) {
        dispatch('catchError', e);
      }
      return {};
    },
    async apiPutTransports({dispatch}, {transport_id, data}) {
      try {
        return await apiAxios.put(`/transports/${transport_id}`, data);
      } catch (e) {
        dispatch('catchError', e);
      }
      return {};
    },
    async apiGetTransport({dispatch}, transport_id) {
      try {
        const response = await apiAxios.get(`/transports/${transport_id}`);
        return response.data;
      } catch (e) {
        dispatch('catchError', e);
      }
      return {};
    },
    async apiDeleteTransport({dispatch}, transport_id) {
      try {
        return await apiAxios.delete(`/transports/${transport_id}`);
      } catch (e) {
        dispatch('catchError', e);
      }
      return {};
    },
  }
}
